<template>
    <div class="maintenance">
        <div class="maintenance__header">
            <Icon
                class="icon"
                name='maintenance'>
            </Icon>
            <router-link class="title" :to="`/maintenances/${maintenance.id}`">
                <span class="title__maintenance-name">{{ maintenance.title }}</span>
            </router-link>
        </div>
        <div class="maintenance__details">
            <div class="maintenance__dates">
                <span>
                    {{ formatTimestamp(maintenance.timestamp_start, 'short') }}
                     -
                    {{ formatTimestamp(maintenance.timestamp_end, 'short') }}
                </span>
            </div>
            <div class="maintenance__content">
                <TextExpander :text="message"
                              :show-more-enabled="showMoreEnabled"
                />
            </div>
        </div>
        <AffectedServices
            v-if="maintenance.services?.length"
            :services="maintenance.services"
        />
    </div>
</template>
<script>
import AffectedServices from '@/components/AffectedServices/AffectedServices.vue'
import Icon from '@/components/Icon.vue'
import TextExpander from '@/components/TextExpander.vue'
import timeOperations from '@/mixins/timeOperations'

export default {
    name: 'Maintenance',
    components: {
        AffectedServices,
        Icon,
        TextExpander
    },
    mixins: [
        timeOperations
    ],
    props: {
        maintenance: {
            type: Object,
            required: true
        },
        showMoreEnabled: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        message() {
            return this.maintenance.message;
        }
    }
}
</script>
<style lang="scss" scoped>
.maintenance {
    overflow: hidden;
    border-radius: 6px;
    background: $backgroundColor;
    box-sizing: border-box;
    margin-bottom: 32px;

    &__header {
        padding: 16px 16px;
        display: flex;

        .icon {
            padding: 0 8px;
        }

        .title {
            font-size: 0.875rem;
            align-self: center;
            text-decoration: none;

            &__maintenance-name {
                color: #0077AA;
                margin-left: 4px;
                text-decoration: underline;
                font-weight: 600;
                letter-spacing: -0.29px;
                line-height: 1.1875rem;
            }
        }
    }

    &__content {
        padding-top: 6px;
    }

    &__dates {
        font-size: 0.8125rem;
        font-weight: 600;
        color: $textColor;
    }

    &__details {
        padding: 24px;
        background-color: #fff;
    }
}
</style>
