import { DateTime, IANAZone } from 'luxon'
import { mapGetters } from 'vuex';
import timezones from '@/timezones.json'
import { findTimeZone, getUTCOffset } from '@protontech/timezone-support/src/index-1970-2038'

const DATE_FORMATS = {
    'ansi': 'LL/dd/yyyy',
    'iso28601': 'dd/LL/yyyy',
    'iso8601': 'yyyy-LL-dd'
}

const TIME_FORMATS = {
    'long:12h': 'hh:mm:ssa',
    'long:24h': 'HH:mm:ss',
    'short:12h': 'hh:mma',
    'short:24h': 'HH:mm'
}

const TIMESTAMP_FORMATS = {
    'long': { date: true, time: 'long', tz: true },
    'short': { date: true, time: 'short', tz: true },
    'date': { date: true, time: false, tz: false },
    'time': { date: false, time: 'short', tz: true }
}

const DEFAULT_DATE_FORMAT = DATE_FORMATS['ansi']
const DEFAULT_TIME_FORMAT = TIME_FORMATS['long:12h']
const DEFAULT_TIMESTAMP_FORMAT = TIMESTAMP_FORMATS['long']

export default {
    computed: {
        ...mapGetters({
                config: 'config',
                timeZone: 'timeZone'
        })
    },
    methods: {
        dateFormat() {
            let name = this.config.locale?.date_format_name ||
                this.dateFormatFromLegacy()
            return DATE_FORMATS[name] || DEFAULT_DATE_FORMAT
        },
        dateFormatFromLegacy() {
            switch (this.config.locale?.name) {
                case 'en':
                    return 'iso28601'
                case 'en-US':
                    return 'ansi'
                default:
                    return 'ansi'
            }
        },
        timeFormat(formatID) {
            const name = this.config.locale?.time_format_name ||
                this.config.locale?.time_format ||
                '12h'
            return TIME_FORMATS[`${formatID}:${name}`] || DEFAULT_TIME_FORMAT
        },
        dateFromTimestamp(timestamp) {
            if ((timestamp || 0) <= 0) {
                return DateTime.fromSeconds(0)
            }

            return DateTime.fromSeconds(timestamp)
        },
        formatTimestamp(timestamp, formatID = 'long') {
            if ((timestamp || 0) === 0) {
                return ''
            }

            const date = this.dateFromTimestamp(timestamp)
            const formatTemplate = TIMESTAMP_FORMATS[formatID] ||
                DEFAULT_TIMESTAMP_FORMAT
            let parts = []
            if (formatTemplate.date) {
                parts.push(this.dateFormat())
            }
            if (formatTemplate.time) {
                parts.push(this.timeFormat(formatTemplate.time))
            }
            const withoutTZ = date
                .setZone(IANAZone.create(this.timeZone.value))
                .toFormat(parts.join(' '))
            if(!formatTemplate.tz) {
                return withoutTZ
            }

            const tz = findTimeZone(this.timeZone.value)
            const offset = getUTCOffset(date.toJSDate(), tz)
            return `${withoutTZ} ${offset.abbreviation}`
        },
        timeZoneByName(name, fallback = 'Etc/UTC') {
            return timezones.find(tz => tz.value === name) ||
                timezones.find(tz => tz.value === fallback)
        }
    }
}
